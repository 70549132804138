<template>
    <div>
        <v-data-table
                :headers="headers"
                :items="Salesitems"
                calculate-widths
                class="mt-3 appic-table-light specification-table"
                dense
                disable-pagination
                disable-sort
                :height="Salesitems.length < 2 ? '150px' : null"
                hide-default-header
                hide-default-footer
                item-key="index"
                single-select
        >
            <template v-slot:header="{ props: { headers } }">
                <tr>
                    <th class="grey lighten-5 pa-1 width-1-pct text-center">{{ headers[0].text }}</th>
                    <th class="grey lighten-5 pa-1 text-no-wrap width-5-pct"><div class="ml-1">{{ headers[1].text }}</div></th>
                    <th class="grey lighten-5 pa-1 red--text width-8-pct"><div class="ml-1">{{ headers[2].text }}*</div></th>
                    <th class="grey lighten-5 pa-1 red--text width-12-pct">{{ headers[3].text }}*</th>
                    <th class="grey lighten-5 pa-1 red--text width-7-pct"><div class="ml-4">{{ headers[4].text }}*</div></th>
                    <th class="grey lighten-5 pa-1 red--text text-right width-8-pct"><div class="mr-3">{{ headers[5].text }}*</div></th>
                    <th class="grey lighten-5 pa-1 red--text text-right width-8-pct"><div class="mr-3">{{ headers[6].text }}*</div></th>
                    <th class="grey lighten-5 pa-1 red--text text-right width-8-pct"><div class="mr-3">{{ headers[7].text }}*</div></th>
                    <th class="grey lighten-5 pa-1 red--text text-right width-5-pct"><div class="mr-5">{{ headers[8].text }}*</div></th>
                    <th class="grey lighten-5 pa-1 red--text width-5-pct text-no-wrap"><div class="mr-5">{{ headers[9].text }}*</div></th>
                    <th class="grey lighten-5 pa-1 red--text text-right"><div class="mr-3">{{ headers[10].text }}*</div></th>
                    <th class="grey lighten-5 pa-1 red--text text-right text-no-wrap width-7-pct"><div class="mr-5">{{ headers[11].text }}</div></th>
                    <th class="grey lighten-5 pa-1 text-right">{{ headers[12].text }}</th>
                    <th class="grey lighten-3 pa-1 width-5-pct">{{ headers[13].text }}</th>
                    <th class="grey lighten-3 pa-1 text-no-wrap width-1-pct">{{ headers[14].text }}</th>
                    <th class="grey lighten-3 pa-1 text-no-wrap width-5-pct">{{ headers[15].text }}</th>
                    <th class="grey lighten-3 pa-1 text-no-wrap width-4-pct">{{ headers[16].text }}</th>
                    <th class="grey lighten-3 pa-1 text-no-wrap width-4-pct">{{ headers[17].text }}</th>
                    <th class="grey lighten-3 pa-1 text-no-wrap width-4-pct">{{ headers[18].text }}</th>
                    <th class="grey lighten-3 pa-1 text-no-wrap width-4-pct">{{ headers[19].text }}</th>
                    <th class="grey lighten-3 pa-1 text-no-wrap">{{ $t('message.actions') }}</th>
                </tr>
            </template>
            <template v-slot:item.lineNumber="{item}">
                <div class="mb-3 ml-1">{{ item.lineNumber }}</div>
            </template>
            <template v-slot:item.Stock.bundleno="{item}">
                <div class="mb-3 ml-1">{{ item.Stock.bundleno }}</div>
            </template>
            <template v-slot:item.Stock.fsc="{item}">
                    <v-select
                            :items=stockFscTypes
                            :hide-details="item.Stock.fsc !== undefined && item.Stock.fsc != null && item.Stock.fsc != '' && item.Stock.fsc.length > 0 "
                            :placeholder="$t('message.na')"
                            :value="item.Stock.fsc !== undefined  && item.Stock.fsc != null  && item.Stock.fsc != '' ? item.Stock.fsc : null"
                            class="custom pa-1"
                            clearable
                            dense
                            reverse
                            v-if="item.type == 'default'"
                            @input="updateSalesItemRowHandle( item.index, 'Stock.fsc',$event)"
                    >
                    </v-select>
                    <v-text-field
                            :value="item.Stock.fsc_description"
                            :placeholder="$t('message.certDesc')"
                            class="custom pa-1"
                            dense
                            hide-details
                            v-if="item.type == 'default' && item.Stock.fsc !== undefined && item.Stock.fsc != null && item.Stock.fsc != '' && item.Stock.fsc.length > 0"
                            @input="updateSalesItemRowHandle( item.index, 'Stock.fsc_description',$event)"
                    >
                    </v-text-field>
            </template>
            <template v-slot:item.Stock.species_id="{item}">
                <v-autocomplete
                        :items=allSpecies
                        :placeholder="$t('message.startTypingToSearch')"
                        :value="item.Species.id"
                        cache-items
                        class="custom pa-1"
                        dense
                        item-value="Species.id"
                        item-text="Species.title"
                        @input="updateSalesItemRowHandle( item.index, 'Stock.species_id',$event)"
                        v-if="item.type == 'default'"
                >
                </v-autocomplete>
            </template>
            <template v-slot:item.Stock.grade_id="{item}">
                <v-autocomplete
                        :items="allGrades[item.index]"
                        :placeholder="$t('message.startTypingToSearch')"
                        :search-input.sync="searchGrades[item.index]"
                        :value="item.Stock.grade_id"
                        cache-items
                        class="custom pa-1"
                        clearable
                        dense
                        item-text="Grade.title"
                        item-value="Grade.id"
                        no-filter
                        @focus="currentRowIndex = item.index"
                        @input="updateSalesItemRowHandle( item.index, 'Stock.grade_id',$event)"
                        return-object
                >
                </v-autocomplete>
            </template>
            <template v-slot:item.Stock.thick="{item}">
                <v-row no-gutters>
                    <v-col>
                        <v-text-field
                                :value="item.Stock.thick"
                                class="custom pa-1 mt-6px"
                                dense
                                @input="updateSalesItemRowHandle( item.index, 'Stock.thick',$event)"
                                v-if="item.type == 'default'"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-select
                                :items=uofmDimensions
                                :value="item.Smeasurement.id"
                                cache-items
                                class="custom pa-0"
                                dense
                                item-text="Measurement.title"
                                item-value="Measurement.id"
                                @input="updateSalesItemRowHandle( item.index, 'Stock.smeasurement_id',$event)"
                                v-if="item.type == 'default'"
                        >
                        </v-select>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.Stock.width="{item}">
                <v-row no-gutters>
                    <v-col>
                        <v-text-field
                                :value="item.Stock.width"
                                class="custom pa-1 mt-6px"
                                dense
                                @input="updateSalesItemRowHandle( item.index, 'Stock.width',$event)"
                                v-if="item.type == 'default'"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-select
                                :items=uofmDimensions
                                :value="item.Wmeasurement.id"
                                cache-items
                                class="custom pa-0"
                                dense
                                item-text="Measurement.title"
                                item-value="Measurement.id"
                                @input="updateSalesItemRowHandle( item.index, 'Stock.wmeasurement_id',$event)"
                                v-if="item.type == 'default'"
                        >
                        </v-select>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.Stock.length="{item}">
                <v-row no-gutters>
                    <v-col>
                        <v-text-field
                                :value="item.Stock.length"
                                class="custom pa-1 mt-6px"
                                dense
                                @input="updateSalesItemRowHandle( item.index, 'Stock.length',$event)"
                                v-if="item.type == 'default'"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-select
                                :items=uofmDimensions
                                :value="item.Lmeasurement.id"
                                cache-items
                                class="custom pa-0"
                                dense
                                item-text="Measurement.title"
                                item-value="Measurement.id"
                                @input="updateSalesItemRowHandle( item.index, 'Stock.lmeasurement_id',$event)"
                                v-if="item.type == 'default'"
                        >
                        </v-select>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.Stock.piece="{item}">
                <v-text-field
                        :value="item.Stock.piece ? item.Stock.piece : 0"
                        class="custom pa-1"
                        dense
                        @input="updateSalesItemRowHandle( item.index, 'Stock.piece',$event)"
                        type="number"
                        v-if="item.type == 'default'"
                >
                </v-text-field>
            </template>
            <template v-slot:item.Stock.volume="{item}">
                <v-text-field
                        :value="item.Stock.volume ? item.Stock.volume : 0"
                        class="custom pa-1"
                        dense
                        @input="updateSalesItemRowHandle( item.index, 'Stock.volume',$event)"
                        type="number"
                        v-if="item.type == 'default'"
                >
                </v-text-field>
            </template>
            <template v-slot:item.Stock.soldmeasurement_id="{item}">
                <v-select
                        :items=uofmQty
                        :value="item.Soldmeasurement.id"
                        cache-items
                        class="custom pa-0"
                        dense
                        item-text="Measurement.title"
                        item-value="Measurement.id"
                        v-if="item.type == 'default'"
                        @input="updateSalesItemRowHandle( item.index, 'Stock.soldmeasurement_id',$event)"
                >
                </v-select>
            </template>
            <template v-slot:item.Stock.sellingprice="{item}">
                <v-text-field
                        :value="item.Stock.sellingprice ? item.Stock.sellingprice : 0"
                        class="custom pa-1"
                        dense
                        @input="updateSalesItemRowHandle( item.index, 'Stock.sellingprice',$event)"
                        type="number"
                        v-if="item.type == 'default'"
                >
                </v-text-field>
            </template>
            <template v-slot:item.Stock.invoiceamount="{item}">
                <div class="text-right mb-3" v-if="item.type == 'default'">{{ formatThisNumber(item.Stock.invoiceamount,currencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.formatPrecision) }}</div>
                <div v-else class="d-inline-flex" style="margin-right: -20px">
                    <v-text-field
                            :value="item.Stock.invoiceamount ? item.Stock.invoiceamount : 0"
                            class="custom pa-1"
                            dense
                            type="number"
                            @input="updateSalesItemRowHandle( item.index, 'Stock.invoiceamount',$event)"
                    >
                    </v-text-field>
                </div>
            </template>
            <template v-slot:item.Stockorder.title="{item}">
                <div :class="'mb-3 ml-1' + (item.Stockorder.title.indexOf(',') != -1 ? ' font-xs' : '') + ' text-no-wrap'" v-if="item.type == 'default'" v-html="stockOrderTitle(item.Stockorder.title)"></div>
            </template>
            <template v-slot:item.Stockorder.currency_id="{item}">
                <div class="mb-3 mr-2" v-if="item.type == 'default'">{{ currencies.find((currency)=>currency.Currency.id == item.Stockorder.currency_id).Currency.code }}</div>
            </template>
            <template v-slot:item.Stock.costprice="{item}">
                <v-row no-gutters>
                    <v-col>
                        <v-text-field
                                :value="item.Stock.costprice ? item.Stock.costprice : 0"
                                class="custom pa-1"
                                dense
                                @input="updateSalesItemRowHandle( item.index, 'Stock.costprice',$event)"
                                type="number"
                                v-if="item.type == 'default'"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.Stock.forwardingcharge="{item}">
                <v-text-field
                        :value="item.Stock.forwardingcharge ? item.Stock.forwardingcharge : 0"
                        class="custom pa-1"
                        dense
                        @input="updateSalesItemRowHandle( item.index, 'Stock.forwardingcharge',$event)"
                        type="number"
                        v-if="item.type == 'default'"
                >
                </v-text-field>
            </template>
            <template v-slot:item.Stock.warehousecost="{item}">
                <v-text-field
                        :value="item.Stock.warehousecost ? item.Stock.warehousecost : 0"
                        class="custom pa-1"
                        dense
                        @input="updateSalesItemRowHandle( item.index, 'Stock.warehousecost',$event)"
                        type="number"
                        v-if="item.type == 'default'"
                >
                </v-text-field>
            </template>
            <template v-slot:item.Stock.totalcost="{item}">
                <div class="text-right mb-3" v-if="item.type == 'default'">{{ formatThisNumber(item.Stock.totalcost,currencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.formatPrecision) }}</div>
            </template>
            <template v-slot:item.Stock.costamount="{item}">
                <div class="text-right mb-3" v-if="item.type == 'default'">{{ formatThisNumber(item.Stock.costamount,currencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.formatPrecision) }}</div>
            </template>
            <template v-slot:item.index="{ item }">
                <div class="text-center" v-if="item.type == 'othercharge'">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="deleteItem(item.index)" class="mb-3" v-on="on">
                                <v-icon color="grey">delete</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('message.deleteThisItem') }}</span>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:footer>
                <div class="pt-0 d-flex justify-space-between">
                    <div class="col-5 pt-5 px-0 d-flex justify-start">
                        <v-btn
                                @click="addOtherChargesItemRow"
                                color="success"
                                small
                        >{{ $t('message.addOtherCharges') }}
                        </v-btn>
                        <v-btn
                                @click="resetLocalSalesitems"
                                class="ml-3"
                                color="default"
                                :loading="resettingLocalSalesitems"
                                small
                        ><v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                        </v-btn>
                    </div>
                    <div class="dense-inputs col-7 pa-0">
                        <v-row justify="end">
                            <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="text-right font-sm font-weight-black">{{ $t('message.totalVolAndQty') }}</div>
                                <div :class="'text-right ' + (itemsTotalQty.length > 1 ? 'font-sm' : 'font-md') + ' font-weight-black d-flex flex-column'">
                                    <span v-for="(total,index) in itemsTotalQty" :class="itemsTotalQty.length > 1 && index == 0  ? 'pt-1' : ''">
                                        {{ formatThisNumber(total.Total.qty, uofmQty.find((uofm)=> uofm.Measurement.title == total.Total.uofm.trim()).Measurement.formatPrecision) + ' '+ total.Total.uofm.trim() }}
                                    </span>
                                </div>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="text-right font-sm font-weight-black red--text">{{ $t('message.invoiceCurrency') + '*' }}</div>
                                <v-container class="ma-0 pa-0">
                                    <v-row no-gutters>
                                        <v-col cols="7" offset="5">
                                            <v-select
                                                    :items="currencies"
                                                    class="custom py-0 mt-0"
                                                    hide-details
                                                    item-text="Currency.code"
                                                    item-value="Currency.id"
                                                    reverse
                                                    style=""
                                                    v-model="Invoice__currency_id"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pb-0">
                                <div class="text-right font-sm font-weight-black">{{ $t('message.totalAmount') + '(' + currencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.code + ')' }}</div>
                                <div class="text-right font-md font-weight-black">{{ formatThisNumber(itemsTotalAmount,currencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.formatPrecision) }}</div>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pb-0">
                                <div class="text-right font-sm font-weight-black">{{ $t('message.totalCost') + '(' + currencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.code + ')' }}</div>
                                <div class="text-right font-md font-weight-black">{{ formatThisNumber(itemsTotalCost,currencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.formatPrecision) }}</div>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pb-0">
                                <div class="text-right font-sm font-weight-black">{{ $t('message.totalMargin') + '(' + currencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.code + ')' }}</div>
                                <div class="text-right font-md font-weight-black">{{ formatThisNumber(itemsTotalMargin,currencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.formatPrecision) }}</div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import {getSafe, numberFormat} from "../../helpers/helpers";
    import { debounce } from "debounce";
    import ForexView from "./ForexView";
    import { api } from "../../api";

    export default {
        name: "LocalSalesItemList",
        components: {ForexView},
        data(){
            return {
                currentRowIndex: 0,
                fieldsWithApi: ['Stock.grade_id','Stock.size_id'],
                filteredSpecs: [],
                filteredSizes: [],
                filteredGrades: [],
                loadingGrades: [],
                loadingSizes: [],
                loadingSpecs: [],
                maxNumberLength: 1000000,
                minNumberLength: 0,
                resettingLocalSalesitems: false,
                searchGrades: [],
                searchSizes: [],
                searchSpecs: [],
            }
        },
        computed: {
            ...mapFields('invoice',{
                Invoice__id: 'current.Invoice.id',
                Invoice__currency_id: 'current.Invoice.currency_id',
                Localsalesorder__id: 'current.Invoice.Localsalesorder.id',
                openForexView: 'openForexView'
            }),
            ...mapMultiRowFields('localsalesitem',{
                Salesitems: 'current.Salesitems'
            }),
            ...mapGetters('localsalesitem',{
                itemsTotalAmount: 'itemsTotalAmount',
                itemsTotalAmountConverted: 'itemsTotalAmountConverted',
                itemsTotalCommission: 'itemsTotalCommission',
                itemsTotalCost: 'itemsTotalCost',
                itemsTotalMargin: 'itemsTotalMargin',
                itemsTotalQty: 'itemsTotalQty',
            }),
            ...mapGetters('species', {
                allSpecies: 'allSpecies',
                findSpeciesById: 'findSpeciesById'
            }),
            ...mapGetters([
                'appicUrls',
                'currencies',
                'stockFscTypes',
                'uofmQty',
                'uofmDimensions'
            ]),
            allGrades(){
                return this.filteredGrades;
            },
            allSizes(){
                return this.filteredSizes;
            },
            allSpecs(){
                return this.filteredSpecs;
            },
            console: () => console,
            headers() {
                let headers = [
                    {
                        text: this.$t('message.lineNo'),
                        value: 'lineNumber',
                    },
                    {
                        text: this.$t('message.bundleNo'),
                        value: 'Stock.bundleno',
                    },
                    {
                        text: this.$t('message.species'),
                        value: 'Stock.species_id',
                    },

                    {
                        text: this.$t('message.grade'),
                        value: 'Stock.grade_id',
                    },
                    {
                        text: this.$t('message.fscPefc'),
                        value: 'Stock.fsc',
                    },
                    {
                        text: this.$t('message.thk'),
                        value: 'Stock.thick',
                    },
                    {
                        text: this.$t('message.wdth'),
                        value: 'Stock.width',
                    },
                    {
                        text: this.$t('message.len'),
                        value: 'Stock.length',
                    },
                    {
                        text: this.$t('message.pcs'),
                        value: 'Stock.piece',
                    },
                    {
                        text: this.$t('message.invoiceVolAndQty'),
                        value: 'Stock.volume',
                    },
                    {
                        text: this.$t('message.unit'),
                        value: 'Stock.soldmeasurement_id',
                    },
                    {
                        text: this.$t('message.invPrice'),
                        value: 'Stock.sellingprice',
                    },
                    {
                        text: this.$t('message.amount'),
                        value: 'Stock.invoiceamount',
                    },
                    {
                        text: this.$t('message.stkOrder'),
                        value: 'Stockorder.title',
                    },
                    {
                        text: this.$t('message.stockOrderCur'),
                        value: 'Stockorder.currency_id',
                    },
                    {
                        text: this.$t('message.buyingPrice'),
                        value: 'Stock.costprice',
                    },
                    {
                        text: this.$t('message.freightCost'),
                        value: 'Stock.forwardingcharge',
                    },
                    {
                        text: this.$t('message.whCost'),
                        value: 'Stock.warehousecost',
                    },
                    {
                        text: this.$t('message.totalCost'),
                        value: 'Stock.totalcost',
                    },
                    {
                        text: this.$t('message.costAmount'),
                        value: 'Stock.costamount',
                    },
                    {
                        text: this.$t('message.actions'),
                        value: 'index'
                    }
                ]
                return headers
            }
        },
        methods: {
            ...mapActions('localsalesitem',{
                deleteLocalSalesItemRow: 'deleteLocalSalesItemRow',
                getSalesItemsBySalesOrder: 'getSalesItemsBySalesOrder',
                getSalesItemsByLocalSale: 'getSalesItemsByLocalSale',
                resetSalesItems: 'resetSalesItems',
                updateSalesItems: 'updateSalesItems',
            }),
            ...mapActions('species', {
                getAllSpecies: 'getAllSpecies'
            }),
            ...mapMutations('localsalesitem',{
                updateTotals: 'updateTotals',
                addOtherChargesItemRow: 'addOtherChargesItemRow'
            }),
            async deleteItem(itemIndex) {
                if (await this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueDeleteItemAction'), {color: 'red'})) {
                    let promise = new Promise((resolve, reject) => {
                        this.deleteLocalSalesItemRow(itemIndex)
                        resolve('ok')
                    });
                    let _this = this
                    promise.then(await _this.updateTotals)
                }
            },
            doGradesSearch: async(rowIndex, value, self) => {
                if(!value){
                    self.filteredGrades[rowIndex] = [];
                }
                if(self.loadingGrades[rowIndex]){
                    return
                }
                self.loadingGrades[rowIndex] = true
                await api
                    .get("/grades", {
                        params: {
                            conditions: [
                                {
                                    field: 'grades.title',
                                    value: value,
                                    textsearch: true
                                }
                            ]
                        }
                    })
                    .then(
                        response => {
                            if (response.data.data.length > 0) {
                                self.filteredGrades[rowIndex] = response.data.data
                            } else {
                                self.filteredGrades[rowIndex] = []
                            }
                        }
                    ).catch(error => {
                            self.error = this.$t('message.unknownError')
                            self.filteredGrades[rowIndex] = []
                        }
                    ).finally(
                        () => (self.loadingGrades[rowIndex] = false)
                    )
            },
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
            loadSalesOrderItems(localsalesorder_id){
                this.getSalesItemsBySalesOrder(localsalesorder_id)
            },
            loadLocalSaleItems(localsale_id){
                this.getSalesItemsByLocalSale(localsale_id)
            },
            stockOrderTitle(value){
                let title = value.split(',');
                let newTitle = '';
                title.forEach (function(item, index) {
                    if(index > 0){
                        newTitle += '<br>' + item.trim()
                    } else {
                        newTitle += item.trim()
                    }
                });
                return newTitle
            },
            resetLocalSalesitems(){

            },
            updateSalesItemRowHandle(idx,field,value) {
                if(this.fieldsWithApi.includes(field)){
                    switch(field) {
                        case 'Stock.grade_id':
                            var payload = {
                                index: idx,
                                field: field,
                                value: value.Grade.id
                            }
                            // assign Grade property in Stock
                            this.$store.commit('localsalesitem/updateSalesItems', {
                                index: idx,
                                field: 'Grade.id',
                                value: value.Grade.id
                            })
                            this.$store.commit('localsalesitem/updateSalesItems', {
                                index: idx,
                                field: 'Grade.title',
                                value: value.Grade.title
                            })
                            break;
                        case 'Stock.size_id':
                            var payload = {
                                index: idx,
                                field: field,
                                value: value.Size.id
                            }
                            // assign Size property in Stock
                            this.$store.commit('localsalesitem/updateSalesItems', {
                                index: idx,
                                field: 'Size.id',
                                value: value.Size.id
                            })
                            this.$store.commit('localsalesitem/updateSalesItems', {
                                index: idx,
                                field: 'Size.title',
                                value: value.Size.title
                            })
                            break;
                    }
                } else {
                    switch(field) {
                        case 'Stock.species_id':
                            var payload = {
                                index: idx,
                                field: field,
                                value: value,
                            }
                            //assign Species property in Stock
                            let species = this.findSpeciesById(value);
                            this.$store.commit('localsalesitem/updateSalesItems', {
                                index: idx,
                                field: 'Species.id',
                                value: getSafe(species) ? species.Species.id : ''
                            })
                            this.$store.commit('localsalesitem/updateSalesItems', {
                                index: idx,
                                field: 'Species.title',
                                value: getSafe(species) ? species.Species.title : ''
                            })
                            break;
                        case 'Stock.smeasurement_id':
                            var payload = {
                                index: idx,
                                field: field,
                                value: value,
                            }
                            //assign Measurement property in
                            let smeasurement = this.uofmDimensions.filter(uofm => uofm.Measurement.id === value)[0];
                            if(smeasurement != null) {
                                this.$store.commit('localsalesitem/updateSalesItems', {
                                    index: idx,
                                    field: 'Smeasurement.id',
                                    value: smeasurement.Measurement.id != null ? smeasurement.Measurement.id : null
                                })
                                this.$store.commit('localsalesitem/updateSalesItems', {
                                    index: idx,
                                    field: 'Smeasurement.title',
                                    value: smeasurement.Measurement.title != null ? smeasurement.Measurement.title : null
                                })
                            }
                            break;
                        case 'Stock.wmeasurement_id':
                            var payload = {
                                index: idx,
                                field: field,
                                value: value,
                            }
                            //assign Measurement property in
                            let wmeasurement = this.uofmDimensions.filter(uofm => uofm.Measurement.id === value)[0];
                            if(wmeasurement != null) {
                                this.$store.commit('localsalesitem/updateSalesItems', {
                                    index: idx,
                                    field: 'Wmeasurement.id',
                                    value: wmeasurement.Measurement.id != null ? wmeasurement.Measurement.id : null
                                })
                                this.$store.commit('localsalesitem/updateSalesItems', {
                                    index: idx,
                                    field: 'Wmeasurement.title',
                                    value: wmeasurement.Measurement.title != null ? wmeasurement.Measurement.title : null
                                })
                            }
                            break;
                        case 'Stock.lmeasurement_id':
                            var payload = {
                                index: idx,
                                field: field,
                                value: value,
                            }
                            //assign Measurement property in
                            let lmeasurement = this.uofmDimensions.filter(uofm => uofm.Measurement.id === value)[0];
                            if(lmeasurement != null) {
                                this.$store.commit('localsalesitem/updateSalesItems', {
                                    index: idx,
                                    field: 'Lmeasurement.id',
                                    value: lmeasurement.Measurement.id != null ? lmeasurement.Measurement.id : null
                                })
                                this.$store.commit('localsalesitem/updateSalesItems', {
                                    index: idx,
                                    field: 'Lmeasurement.title',
                                    value: lmeasurement.Measurement.title != null ? lmeasurement.Measurement.title : null
                                })
                            }
                            break;
                        case 'Stock.soldmeasurement_id':
                            var payload = {
                                index: idx,
                                field: field,
                                value: value,
                            }
                            //assign Measurement property in
                            let soldmeasurement = this.uofmDimensions.filter(uofm => uofm.Measurement.id === value)[0];
                            if(soldmeasurement != null) {
                                this.$store.commit('localsalesitem/updateSalesItems', {
                                    index: idx,
                                    field: 'Soldmeasurement.id',
                                    value: soldmeasurement.Measurement.id != null ? soldmeasurement.Measurement.id : null
                                })
                                this.$store.commit('localsalesitem/updateSalesItems', {
                                    index: idx,
                                    field: 'Soldmeasurement.title',
                                    value: soldmeasurement.Measurement.title != null ? soldmeasurement.Measurement.title : null
                                })
                            }
                            break;
                        default:
                            var payload = {
                                index: idx,
                                field: field,
                                value: value,
                            }
                    }
                }
                let promise = new Promise((resolve, reject) => {
                    this.$store.commit('localsalesitem/updateSalesItems', payload)
                    resolve('ok')
                });
                let _this = this
                promise.then(_this.updateTotals)
            },
        },
        watch: {
            searchGrades(value){
                if(value[this.currentRowIndex] == null ||  value[this.currentRowIndex].length == ''){
                    return
                }
                debounce(this.doGradesSearch,300)(this.currentRowIndex, value[this.currentRowIndex],this)
            },
        },
        mounted(){
            if(!this.allSpecies.length) this.getAllSpecies()
            this.openForexView = false;
            this.filteredGrades = []
            let _this = this
            this.Salesitems.forEach(function(item) {
                let arrayGrade = []
                if(item.Grade.id != '') {
                    let obj = {'Grade':{}}
                    obj['Grade']['id'] = item.Grade.id
                    obj['Grade']['title'] = item.Grade.title
                    arrayGrade.push(obj)
                }
                _this.filteredGrades.push(arrayGrade)
                _this.searchGrades.push('')
                _this.loadingGrades.push(false)
            });



        }
    }
</script>

<style>
    v-text-field__slot input {
        text-align: right;
    }
    .custom.v-text-field {
        font-size: 0.75rem !important;
    }
    .custom.v-select {
        font-size: 0.75rem !important;
    }
    .custom.v-text-field>.v-input__control>.v-input__slot:before {
        border-style: none;
    }
    .custom.v-text-field>.v-input__control>.v-input__slot:after {
        border-style: none;
    }
    .v-data-table td {
        padding: 0 5px;
        font-size: 0.73rem !important;
    }
    .font-xs {
        font-size: 0.65rem;
    }
    .mt-5px {
        margin-top: 5px !important;
    }
    .mt-6px {
        margin-top: 6px !important;
    }
    .custom.v-select .v-icon {
        font-size: 18px !important;
    }
</style>