<template>
    <v-dialog
            hide-overlay
            v-model="openForexView"
            @click:outside="closeView"
    >
        <v-card height="80vh">
            <v-card-text class="pa-4">
                <v-overlay :value="overlay" absolute opacity="0.15">
                    <v-progress-circular indeterminate size="40" width="6"></v-progress-circular>
                </v-overlay>
                <iframe
                        :src="url"
                        frameborder="0px"
                        ref="forexview"
                        @load="onLoad"
                ></iframe>
            </v-card-text>
            <v-card-actions class="pt-0 pb-0">
                <v-spacer></v-spacer>
                <v-btn @click.native="closeView" color="grey" text>{{$t('message.dismiss')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import {mapFields} from "vuex-map-fields";
    export default {
        name: "ForexView",
        data(){
            return {
                overlay: true
            }
        },
        props: ['namespace','url'],
        computed: {
            openForexView: {
                get: function(){
                    return this.$store.getters[this.namespace + '/openForexView']
                },
                set: function(newValue){
                    this.$store.commit(this.namespace + '/setOpenForexView',newValue)
                }
            },
            console: () => console,
        },
        methods: {
            closeView() {
                this.openForexView = false
                this.overlay = true
            },
            onLoad(){
                this.overlay = false;
            }
        },
        mounted() {
            this.overlay = true
        },
        watch: {
            openForexView () {
                if(this.$refs.forexview !== 'undefined'){
                    this.overlay = false
                }
            }
        }
    }
</script>

<style>
    iframe {
        height: 550px;
    }
</style>